import axiosAPI from './axiosApi';

export const preLoginUser = (data) =>
  axiosAPI.post('health/preLogin/', {
    email: data.email.trim().toLowerCase(),
    cat: data.cat,
  });

export const resendOTP = (data) =>
  axiosAPI.post('health/resendOTP/', data);
export const loginUser = (data) =>
  axiosAPI
    .post('health/login/', {
      cat: data.cat,
      email: data.email.trim().toLowerCase(),
      code: data.code,
    })
    .then(({ data, status }) => {
      return new Promise((resolve, reject) => {
        if (status === 200) {
          axiosAPI.defaults.headers['Authorization'] =
            `JWT ${data.token}`;
          localStorage.setItem('access_token', data.access);
          localStorage.setItem('refresh_token', data.refresh);
          localStorage.setItem('role', data.role);
          localStorage.setItem(
            'rightholder',
            JSON.stringify(data.rightholder),
          );
          resolve(data);
        } else {
          reject(data);
        }
      });
    });

export const getAccount = () => axiosAPI.get('health/account/');
export const getAccountByContract = (contract_id) =>
  axiosAPI.get(`health/accountByContract/${contract_id}/`);
export const getDashboardEtiq = (id_entreprise) =>
  axiosAPI.get(`health/getDashboardEtiq/${id_entreprise}/`);

export const subscriptionForm = (requestToken, data) =>
  axiosAPI.post(
    `health/subscriptionForm?request_token=${requestToken}`,
    data,
  );

export const getFile = (contract, awsName) =>
  axiosAPI.get(`health/getFile/${contract}/${awsName}/`, {
    responseType: 'blob',
  });

export const getFileAdherent = async (filename, righholder) => {
  let url = `health/fileAdherent/?filename=${filename}`;
  if (righholder) {
    url += `&rightholder_id=${righholder}`;
  }
  const response = await axiosAPI.get(url, {
    responseType: 'blob',
  });
  return response.data;
};

export const getFiles = () => axiosAPI.get(`health/files/`);

export const postFileAdherent = async (
  file,
  category,
  rightholder,
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('category', category);
  if (rightholder) {
    formData.append('rightholder_id', rightholder);
  }

  return await axiosAPI.post('health/postFileAdherent/', formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
};

export const deleteFileAdherent = async (filename, rightholderId) => {
  let url = `health/deleteFileAdherent/?filename=${filename}`;
  if (rightholderId) {
    url += `&rightholder_id=${rightholderId}`;
  }
  return await axiosAPI.delete(url);
};

export const sendDocumentAdherentFromS3 = async (
  filename,
  rightholderId,
) => {
  let url = `health/sendDocumentAdherentFromS3/?filename=${filename}`;
  if (rightholderId) {
    url += `&rightholder_id=${rightholderId}`;
  }
  return await axiosAPI.get(url);
};

export const getDispenseFile = (awsName) =>
  axiosAPI.get(`health/getDispenseFile/${awsName}/`, {
    responseType: 'blob',
  });
export const getTP = () =>
  axiosAPI.get(`health/getTP/`, { responseType: 'blob' });

export const sendTP = (email, mode) =>
  axiosAPI.post(`health/sendTP/`, { email: email, mode: mode });
export const getTpLetter = () => axiosAPI.get(`health/getTpLetter/`);

export const getJustificatif = () =>
  axiosAPI.get(`health/getJustificatif/`, { responseType: 'blob' });
export const getDecompte = () =>
  axiosAPI.get(`health/getDecompte/`, { responseType: 'blob' });
export const getTemplate = () =>
  axiosAPI.get(`health/getTemplate/`, { responseType: 'blob' });
export const getTemplateImportEmployees = () =>
  axiosAPI.get(`health/getTemplateImportEmployees/`, {
    responseType: 'blob',
  });

export const getDispenseModel = (contract) =>
  axiosAPI.get(`health/getDispenseModel/${contract}/`, {
    responseType: 'blob',
  });
export const getDUE = () =>
  axiosAPI.get(`health/getDUE/`, { responseType: 'blob' });
export const getDSN = (contract, format) =>
  axiosAPI.get(`health/getDSN/${contract}/${format}/`, {
    responseType: 'blob',
  });
export const getCG = (contract) =>
  axiosAPI.get(`health/getCG/${contract}/`, { responseType: 'blob' });
export const getNI = (contract) =>
  axiosAPI.get(`health/getNI/${contract}/`, { responseType: 'blob' });
export const getGaranties = (contract) =>
  axiosAPI.get(`health/getGaranties/${contract}/`, {
    responseType: 'blob',
  });
export const getPortabilite = () =>
  axiosAPI.get(`health/getPortabilite/`, { responseType: 'blob' });
export const getConge = () =>
  axiosAPI.get(`health/getConge/`, { responseType: 'blob' });

export const getIdccs = () => axiosAPI.get(`health/getIdccs/`);
export const getNafs = () => axiosAPI.get(`health/getNafs/`);
export const getContracts = () =>
  axiosAPI.get(`health/getContracts/`);

export const searchEnterprise = (search) =>
  axiosAPI.post('health/searchEnterprise/', search);
export const getEnterprise = (siren) =>
  axiosAPI.get(`health/getEnterprise/${siren}/`);
export const checkSpecificTunnel = (siren) =>
  axiosAPI.get(`health/checkSpecificTunnel/${siren}/`);
export const getMutualisationsChoices = (siren) =>
  axiosAPI.get(`health/getMutualisationsChoices/${siren}/`);
export const getOffer = (data) =>
  axiosAPI.post(`health/getOffer/`, data);
export const getOfferIndividual = () =>
  axiosAPI.get(`health/getOfferIndividual/`);

export const preCall = (data) =>
  axiosAPI.post(`health/preCall`, data);

export const postContract = (data) =>
  axiosAPI.post(`health/contract`, data);
export const putContract = (requestToken, data) =>
  axiosAPI.put(`health/contract?request_token=${requestToken}`, data);
export const signContract = (requestToken, data) =>
  axiosAPI.post(
    `health/signContract?request_token=${requestToken}`,
    data,
  );
export const requestSignTNS = (data) =>
  axiosAPI.post(`health/requestSignTNS/`, data);
export const contractStatus = (requestToken) =>
  axiosAPI.get(`health/contractStatus?request_token=${requestToken}`);

export const sendSubscriptionInvitation = (data) =>
  axiosAPI.post(
    `health/sendSubscriptionInvitation?contract_id=${data?.contractId}`,
    { ...data },
  );

export const importErrorEmployee = (data) =>
  axiosAPI.post(`health/importErrorEmployee/`, data);

export const checkSmsAuthentication = (data) =>
  axiosAPI.post(`health/checkSmsAuthentication/`, data);

export const getPayslips = (
  date,
  contractId,
  pay_closing_date,
  proratisation,
) =>
  axiosAPI.post(`health/getPayslips?contract_id=${contractId}`, {
    date,
    pay_closing_date,
    proratisation,
  });
export const getBills = (id_entreprise) =>
  axiosAPI.get(`health/getBills/${id_entreprise}/`);

export const getEmployees = (contractId, refresh = false) =>
  axiosAPI.get(
    `health/getEmployees?contract_id=${contractId}&refresh=${refresh}`,
  );
export const getEmployeeList = (id_entreprise, status) =>
  axiosAPI.post(
    `health/getEmployeeList/${id_entreprise}/`,
    { status },
    { responseType: 'blob' },
  );

export const getAdmins = (contractId) =>
  axiosAPI.get(`health/getAdmins?contract_id=${contractId}`);
export const getContract = (contractId) =>
  axiosAPI.get(`health/contract?contract_id=${contractId}`);

export const getUserPayslips = (userId, date) =>
  axiosAPI.post(`health/getUserPayslips/${userId}/`, { date });
export const getUserContractDetails = (userId) =>
  axiosAPI.post(`health/getUserContractDetails/${userId}/`);

export const getAydPrice = (relation) =>
  axiosAPI.get(`health/getAydPrice/${relation}/`);
export const getCotisationsDetails = () =>
  axiosAPI.get(`health/getCotisationsDetails/`);
export const getMutationDest = () =>
  axiosAPI.get(`health/getMutationDest/`);

export const updateAccount = (data) =>
  axiosAPI.put('health/updateAccount/', data);

export const getRightholders = (rightholderId) =>
  axiosAPI.get(
    `health/rightholders/${
      !rightholderId ? '' : `?rightholderId=${rightholderId}`
    }`,
  );

export const createRightholder = (data) =>
  axiosAPI.post('health/rightholder/', data);

export const updateRightholder = (id, data) =>
  axiosAPI.put(`health/rightholder/${id}/`, data);

export const deleteRightholder = (id) =>
  axiosAPI.delete(`health/rightholder/${id}/`);
export const resiliateAdh = (data) =>
  axiosAPI.post(`health/resiliateAdh/`, data);

export const createEmployee = (data, contractId) =>
  axiosAPI.post(`health/employee?contract_id=${contractId}`, data);

export const updateEmployee = (id, data) =>
  axiosAPI.put(`health/employee/${id}/`, data);

export const createAdmin = (data, contractId) =>
  axiosAPI.post(`health/admin?contract_id=${contractId}`, data);

export const updateEmployeeAccess = (id, data) =>
  axiosAPI.put(`health/updateEmployeeAccess/${id}/`, data);

export const sendTemplate = (formData) =>
  axiosAPI.post(`health/importTemplate/`, formData);
export const sendImportEmployeesFile = (formData) =>
  axiosAPI.post(`health/importEmployees/`, formData);
export const sendImportEmployees = (formData) =>
  axiosAPI.post(`health/importEmployees/`, formData);
export const sendInvitationEmployees = (formData) =>
  axiosAPI.post(`health/sendInvitationEmployees/`, formData);
export const getEmployee = (id) =>
  axiosAPI.get(`health/employee/${id}/`);
export const removeEmployee = (id) =>
  axiosAPI.delete(`health/employee/${id}/`);
export const createRefund = (data) =>
  axiosAPI.post('health/refund/', data, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });

export const getRefunds = (typeClaim) =>
  axiosAPI.get(`health/getRefunds?type_claim=${typeClaim}`);

export const getPriceOption = () =>
  axiosAPI.get(`health/getPriceOption/`);

export const signingMandat = (id_level_garantie, reason) =>
  axiosAPI.post(`health/signingMandat/`, {
    id_level_garantie,
    reason,
  });
export const updateGuarantee = (id_level_garantie) =>
  axiosAPI.post(`health/updateGuarantee/`, { id_level_garantie });

export const getContractByToken = (requestToken) =>
  axiosAPI.get(
    `health/contractByToken?request_token=${requestToken}`,
  );

export const sendDocument = (id, email, filename) =>
  axiosAPI.post(`today/sendDocument/`, { id, email, filename });

export const resiliateOptionApiCall = () =>
  axiosAPI.post(`health/resiliateOption`);

export const cancelResiliateOptionApiCall = () =>
  axiosAPI.post(`health/cancelResiliateOption`);
