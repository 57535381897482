import {
  getDecompte,
  getDispenseFile,
  getDSN,
  getDUE,
  getFile,
  getJustificatif,
  getTemplate,
  getTemplateImportEmployees,
  getTP,
  getCG,
  getNI,
  getGaranties,
  getPortabilite,
  getConge,
  getFileAdherent,
  getDispenseModel,
} from './axiosRequests';
import { isPhoneNumberValid } from '@operatech1/validators';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React from 'react';
import _ from 'lodash';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import write_blob from 'capacitor-blob-writer';
import { v4 as uuidv4 } from 'uuid';

const isMobileApp = process.env.REACT_APP_IS_MOBILE_APP === 'true';
const fileMobilePrefixVersion = uuidv4();
export const openInNewTab = async (contractId, awsName) => {
  let response = null;
  if (awsName === 'carteTP_ETIQ') {
    response = await getTP();
  } else if (awsName === 'justificatif') {
    response = await getJustificatif();
  } else if (awsName === 'DUE') {
    response = await getDUE();
  } else if (awsName === 'CG') {
    response = await getCG(contractId);
  } else if (awsName === 'NI') {
    response = await getNI(contractId);
  } else if (awsName === 'garanties') {
    response = await getGaranties(contractId);
  } else if (awsName === 'portabilite') {
    response = await getPortabilite();
  } else if (awsName === 'conge') {
    response = await getConge();
  } else if (awsName === 'dispense') {
    response = await getDispenseModel(contractId);
  } else {
    response = await getFile(contractId, awsName);
  }
  if (response) {
    if (isMobileApp) {
      const fileName = `${awsName}-${fileMobilePrefixVersion}.pdf`;
      await checkAndDeleteIfFileExists(fileName);
      write_blob({
        path: fileName,
        directory: Directory.Documents,
        blob: response.data,
        fast_mode: true,
        recursive: true,
        on_fallback(error) {
          console.log(error);
        },
      })
        .then(() => {
          console.log('Blob has been created for : ', fileName);
          return Filesystem.getUri({
            path: fileName,
            directory: Directory.Documents,
          });
        })
        .then(({ uri }) => {
          console.log('URI has been fetched : ', uri);
          return FileOpener.open({
            filePath: uri,
            contentType: 'application/pdf',
          });
        })
        .then(() => console.log('File has been opened on device'))
        .catch((error) => handleMobileDownloadFileError(error));
    } else {
      let url = window.URL.createObjectURL(response.data);
      let a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.click();
    }
  }
};

export const date_yyyy_mm_dd = (date) => {
  if (!date) return null;
  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();

  return [
    date.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
  ].join('-');
};

export const date_dd_mm_yyyy = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const download = async (response) => {
  if (response) {
    console.log(response.data);
    let url = window.URL.createObjectURL(response.data);
    let a = document.createElement('a');
    a.href = url;
    a.download =
      response.headers['content-disposition'].split('filename=')[1];
    a.click();
    window.URL.revokeObjectURL(url);
    return;
  }
};

export const downloadFile = async (contractId, awsName) => {
  let response = null;
  try {
    if (awsName === 'carteTP_ETIQ') {
      response = await getTP();
    } else if (awsName === 'ficheDSN_XML') {
      response = await getDSN(contractId, 'XML');
    } else if (awsName === 'ficheDSN_PDF') {
      response = await getDSN(contractId, 'PDF');
    } else if (awsName === 'template_mailing_list') {
      response = await getTemplate();
    } else if (awsName === 'ETIQ_importation_effectif') {
      response = await getTemplateImportEmployees();
    } else if (awsName === 'justificatif') {
      response = await getJustificatif();
    } else if (awsName === 'decompte') {
      response = await getDecompte();
    } else if (awsName === 'DUE') {
      response = await getDUE();
    } else if (awsName === 'CG') {
      response = await getCG(contractId);
    } else if (awsName === 'NI') {
      response = await getNI(contractId);
    } else if (awsName === 'garanties') {
      response = await getGaranties(contractId);
    } else if (awsName === 'portabilite') {
      response = await getPortabilite();
    } else if (awsName === 'conge') {
      response = await getConge();
    } else if (awsName === 'dispense') {
      response = await getDispenseModel();
    } else {
      response = await getFile(contractId, awsName);
    }

    if (response) {
      if (isMobileApp) {
        const fileName = `${awsName}-${fileMobilePrefixVersion}.pdf`;
        await checkAndDeleteIfFileExists(fileName);
        write_blob({
          path: fileName,
          directory: Directory.Documents,
          blob: response.data,
          fast_mode: true,
          recursive: true,
          on_fallback(error) {
            console.log(error);
          },
        })
          .then(() => {
            console.log('Blob has been created for : ', fileName);
            return Filesystem.getUri({
              path: fileName,
              directory: Directory.Documents,
            });
          })
          .then(({ uri }) => {
            console.log('URI has been fetched : ', uri);
            return FileOpener.open({
              filePath: uri,
              contentType: 'application/pdf',
            });
          })
          .then(() => console.log('File has been opened on device'))
          .catch((error) => handleMobileDownloadFileError(error));
      } else {
        let url = window.URL.createObjectURL(response.data);
        let a = document.createElement('a');
        a.href = url;
        a.download = awsName;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    }
  } catch (error) {
    return await error.text();
  }
};

export const downloadFileAdherent = async ({
  filename,
  openingFile,
  rightholderId,
}) => {
  let response = null;
  try {
    response = await getFileAdherent(filename, rightholderId);
    if (response) {
      const contentType = response.type;
      if (isMobileApp) {
        const fileName = `${filename}-${fileMobilePrefixVersion}.pdf`;
        await checkAndDeleteIfFileExists(fileName);
        write_blob({
          path: fileName,
          directory: Directory.Documents,
          blob: response,
          fast_mode: true,
          recursive: true,
          on_fallback(error) {
            console.log(error);
          },
        })
          .then(() => {
            return Filesystem.getUri({
              path: fileName,
              directory: Directory.Documents,
            });
          })
          .then(({ uri }) => {
            return FileOpener.open({
              filePath: uri,
              contentType: contentType,
            });
          })
          .catch((error) => handleMobileDownloadFileError(error));
      } else {
        let formatName = filename?.split('/');
        let url = window.URL.createObjectURL(response);
        let a = document.createElement('a');
        a.href = url;
        if (openingFile) {
          a.target = '_blank';
        } else {
          a.name = filename;
          a.download = formatName[formatName.length - 1];
        }
        a.click();
        window.URL.revokeObjectURL(url);
      }
    }
  } catch (error) {
    return await error;
  }
};

export const downloadDispenseFile = async (awsName) => {
  const response = await getDispenseFile(awsName);
  if (response) {
    let url = window.URL.createObjectURL(response.data);
    let a = document.createElement('a');
    a.href = url;
    a.download = awsName;
    a.click();
    window.URL.revokeObjectURL(url);
  }
};

export function getMonthDifference(startDate, endDate) {
  const deltaDay = endDate.getDate() >= startDate.getDate() ? 0 : -1;
  return (
    deltaDay +
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  );
}

export function checkNir(nir) {
  if (!nir) return false;
  nir = typeof nir === 'string' ? nir : nir?.toString();
  if (_.size(nir) !== 15) return false;
  nir = nir.replace('2A', 19);
  nir = nir.replace('2B', 18);
  var checkSum = parseInt(nir.substring(13), 10);
  var ssNbr = parseInt(nir.substring(0, 13), 10);
  var calculateCheckSum = Math.abs((ssNbr % 97) - 97);
  return checkSum === calculateCheckSum;
}

export function checkOrganism(organism) {
  if (_.isEmpty(organism)) return true;
  else return organism.match(/^[0-9]{9}$/, '');
}

export function checkManualPostalCode(postalCode) {
  return (
    !postalCode ||
    (!_.isNaN(parseInt(postalCode)) && postalCode?.length === 5)
  );
}

export const dirtyValues = (dirtyFields, allValues) => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ]),
  );
};

export function DatePickerActionBar({ onAccept, onCancel }) {
  return (
    <DialogActions>
      <Button onClick={onCancel}>Annuler</Button>
      <Button onClick={onAccept}>Valider</Button>
    </DialogActions>
  );
}

const handleMobileDownloadFileError = (error) => {
  console.log(
    'An error occurred during create and download file for mobile : ',
    error,
  );
  alert(
    "Une erreur s'est produite lors de la génération du fichier, merci d'utiliser l'envoi par email.",
  );
};

const checkAndDeleteIfFileExists = async (fileName) =>
  Filesystem.readFile({
    path: fileName,
    directory: Directory.Documents,
  })
    .then((data) => {
      console.log('File exists : ', data);
      Filesystem.deleteFile({
        path: fileName,
        directory: Directory.Documents,
      })
        .then(() => console.log('File has been deleted : ', fileName))
        .catch((error) =>
          console.log('Unable to delete file : ', error),
        );
    })
    .catch((error) => {
      console.log('File does not exists : ', error);
    });

export function checkIsFrenchPhoneNumberValid(phoneNumber) {
  return isPhoneNumberValid(phoneNumber, 'FR');
}

export const formatManualAddress = (manualAddress) => {
  return [
    {
      long_name: manualAddress?.street_number,
      short_name: manualAddress?.street_number,
      types: ['street_number'],
    },
    {
      long_name: manualAddress?.address,
      short_name: manualAddress?.address,
      types: ['route'],
    },
    {
      long_name: manualAddress?.city,
      short_name: manualAddress?.city,
      types: ['locality', 'political'],
    },
    {
      long_name: 'France',
      short_name: 'FR',
      types: ['country', 'political'],
    },
    {
      long_name: manualAddress?.postal_code,
      short_name: manualAddress?.postal_code,
      types: ['postal_code'],
    },
  ];
};

export const formatGoogleMapsAddress = (addressComponents) => {
  let route = '';

  addressComponents.forEach((component) => {
    if (component.types.includes('route')) {
      route = component.long_name;
    }
  });
  return route;
};

export const subtractYearsFromDate = (years) => {
  const today = new Date();
  const newDate = new Date(
    today.setFullYear(today.getFullYear() - years),
  );

  return newDate;
};
